import { getCookie, setCookie } from './components/cookie';
import { ajaxDebug } from './components/ajax-debug-log';
// import qs from 'qs';
import 'jquery-validation/dist/jquery.validate';
// import axios from 'axios/dist/axios';
import '@fancyapps/fancybox';
// import Chart from 'chart.js/dist/chart.js';

import noUiSlider from 'nouislider/dist/nouislider';

jQuery(document).ready(($) => {
  if ( getCookie('revenue') ) {
    $('#revenue').val(getCookie('revenue'));
  }

  if ( getCookie('ebitda') ) {
    $('#ebitda').val(getCookie('ebitda'));
  }

  if ( getCookie('doctors') ) {
    $('#f-time-doctors').val(getCookie('doctors'));
  }

  if ( getCookie('rooms') ) {
    $('#exam-rooms').val(getCookie('rooms'));
  }

  if ( getCookie('multiple') ) {
    $('#multiple-today').val(getCookie('multiple'));
    $('.js-multiple-other').text(getCookie('multiple'));
    console.log(getCookie('multiple'));
  }

  $(document).on('change input', '#f-time-doctors', function() {
    if ( $(this).val() == '1' ) {
      $('#multiple-today').val('3');
    } else {
      if ( $('.js-popup-signup-steps').length ) {
        $('#multiple-today').val('10');
      } else {
        $('#multiple-today').val('15');
      }
    }
  });

  const convertCurrencyToNumbers = value => {
    return value.toString().replace(/,/g,'') * 1;
  };

  //practice owner slider
  const calcSlider2 = document.getElementById('calc-slider2');

  if ( calcSlider2 ) {
    const coef1 = $('#f-time-doctors').val() == '1' ? 5 : 10;
    const revenue = convertCurrencyToNumbers($('#revenue').val());
    const ebitda = $('#ebitda').val() * 1 / 100;
    const defaultSliderVal2 = Math.round( revenue * ebitda * coef1 / 1000 ) * 1000;
    const minSliderVal2 = Math.round( revenue * ebitda * coef1 / 2 / 1000 ) * 1000 / 2;
    let startVal = '';

    if ( $('#calc-slider2').attr('data-current-val') ) {
      startVal = convertCurrencyToNumbers($('#calc-slider2').attr('data-current-val'));
    } else {
      if ( getCookie('currentSliderVal') ) {
        startVal = getCookie('currentSliderVal');
      } else {
        startVal = defaultSliderVal2 / 2;
      }
    }

    // console.log($('#calc-slider2').attr('data-current-val'));
    // const step2 = (defaultSliderVal2 - minSliderVal2) / 9;
    $('.js-slider-max-val').text(`$ ${new Intl.NumberFormat('en-US').format(defaultSliderVal2)}`);
    $('.calc-slider2 .js-min-val').text(`$ ${new Intl.NumberFormat('en-US').format(minSliderVal2)}`);
    $('.calc-slider2 .js-max-val').text(`$ ${new Intl.NumberFormat('en-US').format(defaultSliderVal2)}`);

    noUiSlider.create(calcSlider2, {
      start: startVal,
      animate: false,
      step: 1000,
      connect: [true, false],
      range: {
        min: minSliderVal2,
        max: defaultSliderVal2 ? defaultSliderVal2 * 1 : 1,
      },
    });
  }

  const calcSlider3 = document.getElementById('calc-slider3');

  if ( calcSlider3 ) {
    $('.calc-slider3 .js-min-val').text(`$ ${new Intl.NumberFormat('en-US').format('1600000')}`);
    $('.calc-slider3 .js-max-val').text(`$ ${new Intl.NumberFormat('en-US').format('6400000')}`);

    noUiSlider.create(calcSlider3, {
      start: 2781000,
      animate: false,
      step: 1000,
      connect: [true, false],
      range: {
        min: 1600000,
        max: 6400000,
      },
    });
  }

  $(document).on('change input', '.calc-section #revenue, .calc-section  #ebitda, .calc-section #f-time-doctors', function() {
    const coef1 = $('#f-time-doctors').val() == '1' ? 5 : 10;
    // console.log(coef1);
    const revenue = convertCurrencyToNumbers($('#revenue').val());
    const ebitda = $('#ebitda').val() * 1 / 100;
    const defaultSliderVal2 = Math.round( revenue * ebitda * coef1 / 1000 ) * 1000;
    const minSliderVal2 = Math.round( revenue * ebitda * coef1 / 2 / 1000 ) * 1000 / 2;
    const startVal = $('#calc-slider2').attr('data-current-val') ? convertCurrencyToNumbers($('#calc-slider2').attr('data-current-val')) : defaultSliderVal2 / 2;
    // const step2 = (defaultSliderVal2 - minSliderVal2) / 9;

    $('.js-slider-max-val').text(`$ ${new Intl.NumberFormat('en-US').format(defaultSliderVal2)}`);
    $('.calc-slider2 .js-min-val').text(`$ ${new Intl.NumberFormat('en-US').format(minSliderVal2)}`);
    $('.calc-slider2 .js-max-val').text(`$ ${new Intl.NumberFormat('en-US').format(defaultSliderVal2)}`);
    calcSlider2.noUiSlider.updateOptions({
      // step: step2,
      start: Math.round(startVal / 1000 ) * 1000,
      range: {
        min: minSliderVal2,
        max: defaultSliderVal2 ? defaultSliderVal2 * 1 : 1,
      },
    });
  });

  //currency input
  $('.js-currency').on( 'input', function( event ) {
    var selection = window.getSelection().toString();
    if ( selection !== '' ) {
      return;
    }
    // When the arrow keys are pressed, abort.
    if ( $.inArray( event.keyCode, [38,40,37,39] ) !== -1 ) {
      return;
    }

    const $this = $( this );

    // Get the value.
    let input = $this.val();
    input = input.replace(/[\D\s\._\-]+/g, "");
    input = input ? parseInt( input, 10 ) : 0;

    $this.val( function() {
      return ( input === 0 ) ? "" : input.toLocaleString( "en-US" );
    } );
  } );

  // const formatNumberToCurrency = selector => {
  //   const $this = $( selector );

  //   // Get the value.
  //   let input = $this.val();
  //   input = input.replace(/[\D\s\._\-]+/g, "");
  //   input = input ? parseInt( input, 10 ) : 0;

  //   $this.val( function() {
  //     return ( input === 0 ) ? "" : input.toLocaleString( "en-US" );
  //   } );
  // };

  //numbers only
  $('.js-num').keypress(function(event) {
    if ( (event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57) ) {
      event.preventDefault();
    }
  });

  // let chart = '';

  //PRACTICE OWNER CALULATION
  // if ( $('#calc-chart-owner').length ) {
  //   Chart.defaults.color = '#fff';
  //   Chart.defaults.font.size = 11;
  //   chart = new Chart(
  //     document.getElementById('calc-chart-owner'),
  //     {
  //       type: 'bar',
  //       data: {
  //         labels: ['Series (C) - Exit value with Galaxy in 5 years', 'Series (B) - Exit value with Galaxy in 5 years', 'Today Valuation With others'],
  //         datasets: [{
  //           data: [80219469, 263004043, 31590000],
  //           backgroundColor: [
  //             '#7D24B4',
  //             '#83B3FC',
  //             '#83FCED',
  //           ],

  //         }],
  //       },
  //       options: {
  //         responsive: true,
  //         plugins: {
  //           legend: false,
  //           tooltip: {
  //             callbacks: {
  //               label: function(context) {
  //                   var label = context.dataset.label || '';

  //                   if (label) {
  //                     label += ': ';
  //                   }
  //                   if (context.parsed.y !== null) {
  //                     label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
  //                   }
  //                   return label;
  //               },
  //             },
  //           },
  //         },
  //         scales: {
  //           y: {
  //             beginAtZero: false,
  //             grid: {
  //               // borderColor: 'rgba(255, 255, 255, 0.1)',
  //               color: 'rgba(255, 255, 255, 0.1)',
  //             },
  //             ticks: {
  //               callback: function(label, index, labels) {
  //                 return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(label);
  //               },
  //             },
  //           },
  //           x: {
  //             grid: {
  //               // borderColor: 'rgba(255, 255, 255, 0.1)',
  //               color: 'rgba(255, 255, 255, 0.1)',
  //             },
  //           },
  //           // yAxes: [

  //           // ],
  //         },
  //       },
  //     }
  //   );
  // }

  if ( $('.js-calc-form').length ) {
    $('.js-calc-form').validate({
      revenue: {
        required: true,
      },
      ebitda: {
        required: true,
      },
      today_check: {
        required: true,
      },
      multiple_today: {
        required: true,
      },
    });
  }

  const calcValuationWithOther = () => {
    const revenue = convertCurrencyToNumbers($('#revenue').val());
    const ebitda = $('#ebitda').val() * 1 / 100;
    const multipleToday = $('#multiple-today').val() * 1;

    const calculatedVal = revenue * ebitda * multipleToday;

    return calculatedVal;
  };

  const calcValuationWithGalaxy = () => {
    const revenue = convertCurrencyToNumbers($('#revenue').val());
    const ebitda = $('#ebitda').val() * 1 / 100;

    const calculatedVal = revenue * ebitda * 10;

    return calculatedVal;
  };

  const calcSharePrice = () => {
    return 1.5;
  };

  const calcRolledEquity = () => {
    const todayCheck = convertCurrencyToNumbers(calcSlider2.noUiSlider.get());
    // const todayCheck = convertCurrencyToNumbers($('#today-check').val());
    const calcValuation = calcValuationWithGalaxy();

    const calculatedVal = calcValuation - todayCheck;

    // console.log(calculatedVal);
    return calculatedVal;
  };

  const calcSharesPurchased = () => {
    const revenue = convertCurrencyToNumbers($('#revenue').val());
    const ebitda = $('#ebitda').val() * 1 / 100;
    const todayCheck = convertCurrencyToNumbers(calcSlider2.noUiSlider.get());
    // const todayCheck = convertCurrencyToNumbers($('#today-check').val());
    // console.log(revenue);
    const sharePrice = calcSharePrice();

    const calculatedVal = ((revenue * ebitda * 10) - todayCheck) / sharePrice;

    // console.log(calculatedVal);
    return calculatedVal;
  };

  const calcExitSharePrice = () => {
    return 14.79;
  };

  const calcExitValue = () => {
    const todayCheck = convertCurrencyToNumbers(calcSlider2.noUiSlider.get());
    const exitSharePrice = calcExitSharePrice();
    const sharePrice = calcSharePrice();
    const revenue = convertCurrencyToNumbers($('#revenue').val());
    const ebitda = $('#ebitda').val() * 1 / 100;
    const coef1 = $('#f-time-doctors').val() == '1' ? 5 : 10;
    const totalValuation = revenue * ebitda * coef1;

    // const sharesPurchased = calcSharesPurchased();

    const calculatedVal = todayCheck + ( ( totalValuation - todayCheck ) / sharePrice ) * exitSharePrice;
    // console.log(calculatedVal);

    return calculatedVal;
  };

  const calcExitEbitda = () => {
    const revenue = convertCurrencyToNumbers( $('#revenue').val());
    const ebitda = $('#ebitda').val() * 1 / 100;
    const exitValue = calcExitValue();
    // const todayCheck = convertCurrencyToNumbers(calcSlider2.noUiSlider.get());

    // console.log(exitValue);

    const calculatedVal = exitValue / (revenue * ebitda);

    return calculatedVal;
  };

  const calcSeriesC = () => {
    const revenue = convertCurrencyToNumbers($('#revenue').val());
    const ebitda = $('#ebitda').val() * 1 / 100;
    const todayCheck = convertCurrencyToNumbers(calcSlider2.noUiSlider.get());
    // const todayCheck = convertCurrencyToNumbers($('#today-check').val());
    const sharePrice = 1.75;

    const sharesPurchased = ((revenue * ebitda * 10) - todayCheck) / sharePrice;

    const calculatedVal = sharesPurchased * 14.793792925;

    return calculatedVal;
  };

  const calcSeriesD = () => {
    const revenue = convertCurrencyToNumbers($('#revenue').val());
    const ebitda = $('#ebitda').val() * 1 / 100;
    const todayCheck = convertCurrencyToNumbers(calcSlider2.noUiSlider.get());
    // const todayCheck = convertCurrencyToNumbers($('#today-check').val());
    const sharePrice = 2.93501877;

    const sharesPurchased = ((revenue * ebitda * 10) - todayCheck) / sharePrice;
    // console.log(sharesPurchased);

    const calculatedVal = sharesPurchased * 14.793792925;

    return calculatedVal;
  };

  const calcExitEbitda2 = () => {
    const exitValue = calcSeriesC();
    const revenue = convertCurrencyToNumbers($('#revenue').val());
    const ebitda = $('#ebitda').val() * 1 / 100;
    const todayCheck = convertCurrencyToNumbers(calcSlider2.noUiSlider.get());

    const calculatedVal = (exitValue + todayCheck) / (revenue * ebitda);

    return calculatedVal;
  };

  const calcExitEbitda3 = () => {
    const exitValue = calcSeriesD();
    const revenue = convertCurrencyToNumbers($('#revenue').val());
    const ebitda = $('#ebitda').val() * 1 / 100;
    const todayCheck = convertCurrencyToNumbers(calcSlider2.noUiSlider.get());

    const calculatedVal = (exitValue + todayCheck)  / (revenue * ebitda);

    // console.log(exitValue);

    return calculatedVal;
  };

  const calcSeriesB = () => {
    const exitValue = calcExitValue();

    const calculatedVal = Math.round(exitValue);

    return calculatedVal;
  };

  const setCalcResults = () => {
    const seriesB = calcSeriesB();
    const seriesC = calcSeriesC();
    const seriesD = calcSeriesD();

    $('.js-valuation-other').text(new Intl.NumberFormat('en-US').format(Math.round(calcValuationWithOther() / 1000) * 1000));
    $('.js-valuation-galaxy').text(new Intl.NumberFormat('en-US').format(calcValuationWithGalaxy()));
    $('.js-share-price').text(calcSharePrice().toFixed(2));
    $('.js-rolled-equity').text(new Intl.NumberFormat('en-US').format(calcRolledEquity()));
    $('.js-shares-purchased').text(Math.round(calcSharesPurchased()));
    $('.js-exit-share-price').text(calcExitSharePrice());
    $('.js-exit-value').text(new Intl.NumberFormat('en-US').format(Math.round(calcExitValue() / 1000) * 1000));
    $('.js-exit-value-1-2').text(new Intl.NumberFormat('en-US').format(Math.round(seriesC / 1000) * 1000));
    $('.js-exit-value-1-3').text(new Intl.NumberFormat('en-US').format(Math.round(seriesD / 1000) * 1000));
    $('.js-exit-ebitda').text(Math.round(calcExitEbitda()));
    $('.js-exit-ebitda-1-2').text(Math.round(calcExitEbitda2()));
    $('.js-exit-ebitda-1-3').text(Math.round(calcExitEbitda3()));

    if ( calcValuationWithOther() > calcExitValue() ) {
      $('.exit-val-wrap').addClass('is-red');
    } else {
      $('.exit-val-wrap').removeClass('is-red');
    }
    // const valuationWithOther = calcValuationWithOther();
    // chart.data.datasets[0].data[0] = Math.round(seriesC);
    // chart.data.datasets[0].data[1] = Math.round(seriesB);
    // chart.data.datasets[0].data[2] = valuationWithOther;
    // chart.data.datasets[0].data.push(seriesB, seriesC, valuationWithOther);
    // console.log(chart.data.datasets[0].data);
    // chart.update();
  }

  $('.js-calc-form').on('submit', (event, param) => {
    const formValidation = $('.js-calc-form').valid();
    const security = $('#calcsecurity').val();
    const revenue = convertCurrencyToNumbers($('#revenue').val());
    const ebitda = $('#ebitda').val();
    const todayCheck = convertCurrencyToNumbers(calcSlider2.noUiSlider.get());
    // const todayCheck = convertCurrencyToNumbers($('#today-check').val());
    const multipleToday = $('#multiple-today').val();
    const examRooms = $('#exam-rooms').val();
    const userID = $('.js-user-id').val();
    const userRole = $('.js-user-role1').val();
    const hsListId = $('.js-hs-id').val();
    const email = $('.js-email').val();
    // const hutk = getCookie('hubspotutk');

    if ( formValidation != true ) {
      return;
    }

    window.dataLayer.push({
      'event': 'calculate',
      'role': userRole,
    });

    setCalcResults();

    $.when( $.ajax({
      url: '/wp-admin/admin-ajax.php',
      method: 'post',
      dataType: 'JSON',
      data: {
        'action': 'calc_users_meta',
        'user_id': userID,
        'today_check': todayCheck,
        'multiple_today': multipleToday,
        'exam_rooms': examRooms,
        'ebitda': ebitda,
        'revenue': revenue,
        'security': security,
      },
    }))
    .then(
      res => {
        if ( hsListId ) {
          $.when( $.ajax({
            url: `https://api.hsforms.com/submissions/v3/integration/submit/20247445/${hsListId}`,
            method: 'post',
            contentType: 'application/json',
            data: JSON.stringify({
              "submittedAt": Date.now(), // This millisecond timestamp is optional. Update the value from "1517927174000" to avoid an INVALID_TIMESTAMP error.
              "fields": [
                {
                  "name": "email",
                  "value": email,
                },
                {
                  "name": "revenue",
                  "value": revenue,
                },
                {
                  "name": "ebitda___",
                  "value": ebitda,
                },
                {
                  "name": "multiple_today",
                  "value": multipleToday,
                },
                {
                  "name": "today_check",
                  "value": todayCheck,
                },
                {
                  "name": "number_of_exam_rooms",
                  "value": examRooms,
                },
              ],
              "context": {
                // "hutk": hutk,
                "pageUri": window.location.href,
                "pageName": $('head title').text(),
                "ipAddress": window.userIP ? window.userIP : undefined,
              },
            }),
          }))
          .then( res => {
            console.log(res);

            if ( param ) {
              $('.js-calc-rev2-next-step').removeClass('is-loading');

              window.location.href = param;
            }
          })
          .catch( e => {
            const errorMsg = `time: ${new Date().toUTCString()}, email: ${email}, ${JSON.stringify(e)}`;
            ajaxDebug(errorMsg);
            console.log(errorMsg);

            if ( param ) {
              $('.js-calc-rev2-next-step').removeClass('is-loading');

              window.location.href = param;
            }
          });
        }
      },
      e => {
        const errorMsg = `time: ${new Date().toUTCString()}, email: ${email}, ${JSON.stringify(e)}`;
        ajaxDebug(errorMsg);
        console.log(errorMsg);

        if ( param ) {
          $('.js-calc-rev2-next-step').removeClass('is-loading');

          window.location.href = param;
        }
      }
    );

    return false;
  });

  if ( $('.js-new-user').length ) {
    $('.js-calc-form').trigger('submit');
  }

  $(document).on('click', '.js-calc-rev2-next-step', e => {
    const that = $(e.currentTarget);
    that.addClass('is-loading');
    $('.js-calc-form').trigger('submit', that.attr('href'));

    return false;
  })

  if ( $('.js-calc-form').length ) {
    setCalcResults();
  }

  //INVESTOR CALULATION
  // if ( $('#calc-chart-investor').length ) {
  //   Chart.defaults.color = '#fff';
  //   Chart.defaults.font.size = 11;
  //   chart = new Chart(
  //     document.getElementById('calc-chart-investor'),
  //     {
  //       type: 'bar',
  //       data: {
  //         labels: ['Join in before Dec 2021', 'Join in Jan 2022 - Dec 2025'],
  //         datasets: [{
  //           data: [1965161, 599398],
  //           backgroundColor: [
  //             '#7D24B4',
  //             '#83B3FC',
  //           ],

  //         }],
  //       },
  //       options: {
  //         responsive: true,
  //         plugins: {
  //           legend: false,
  //           tooltip: {
  //             callbacks: {
  //               label: function(context) {
  //                   var label = context.dataset.label || '';

  //                   if (label) {
  //                     label += ': ';
  //                   }
  //                   if (context.parsed.y !== null) {
  //                     label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
  //                   }
  //                   return label;
  //               },
  //             },
  //           },
  //         },
  //         scales: {
  //           y: {
  //             beginAtZero: false,
  //             grid: {
  //               // borderColor: 'rgba(255, 255, 255, 0.1)',
  //               color: 'rgba(255, 255, 255, 0.1)',
  //             },
  //             ticks: {
  //               callback: function(label, index, labels) {
  //                 return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(label);
  //               },
  //             },
  //           },
  //           x: {
  //             grid: {
  //               // borderColor: 'rgba(255, 255, 255, 0.1)',
  //               color: 'rgba(255, 255, 255, 0.1)',
  //             },
  //           },
  //         },
  //       },
  //     }
  //   );
  // }

  if ( $('.js-calc-form2').length ) {
    $('.js-calc-form2').validate({
      lab_expenses_month: {
        required: true,
      },
      ref_cases: {
        required: true,
      },
    });
  }

  //investors slider
  const calcSlider = document.getElementById('calc-slider');

  if ( calcSlider ) {
    let defaultSliderVal = $('#calc-slider').attr('data-current-val') * 1 > 20000 ? convertCurrencyToNumbers($('#calc-slider').attr('data-current-val')) : 21000;
    defaultSliderVal = Math.round( defaultSliderVal / 1000 ) * 1000;
    const minSliderVal = defaultSliderVal / 2;
    const startVal = $('#calc-slider').attr('data-current-val') ? convertCurrencyToNumbers($('#calc-slider').attr('data-current-val')) : defaultSliderVal / 2;
    // const step = (defaultSliderVal - minSliderVal) / 9;
    $('.calc-slider2 .js-min-val').text(`$ ${new Intl.NumberFormat('en-US').format(minSliderVal)}`);
    $('.calc-slider2 .js-max-val').text(`$ ${new Intl.NumberFormat('en-US').format(defaultSliderVal)}`);

    noUiSlider.create(calcSlider, {
      start: Math.round(startVal / 1000 ) * 1000,
      step: 1000,
      animate: false,
      connect: [true, false],
      range: {
        min: minSliderVal,
        max: defaultSliderVal,
      },
    });

    calcSlider.noUiSlider.on('update', function (values, handle) {
      $('.js-calc-slider-val').text(`$ ${new Intl.NumberFormat('en-US').format(values[handle])}`);
    });
  }


  const calcInvestmentAllowed = () => {
    const labExpensesYear = convertCurrencyToNumbers($('#lab_expenses_month').val()) * 12;
    const refCases = $('#ref_cases').val() * 1;
    const calculatedVal = Math.round( labExpensesYear * refCases / 5 / 1000 ) * 1000;
    const minSliderVal = calculatedVal / 2;

    // const startVal = $('#calc-slider').attr('data-current-val') ? convertCurrencyToNumbers($('#calc-slider').attr('data-current-val')) : calculatedVal;

    $('.js-investement-alowed').text(`${new Intl.NumberFormat('en-US').format(calculatedVal)}`);
    $('.calc-slider2 .js-min-val').text(`$ ${new Intl.NumberFormat('en-US').format(minSliderVal)}`);
    $('.calc-slider2 .js-max-val').text(`$ ${new Intl.NumberFormat('en-US').format(calculatedVal)}`);

    // console.log(calculatedVal);

    calcSlider.noUiSlider.updateOptions({
      // start: Math.round(startVal / 1000 ) * 1000,
      range: {
        'min': minSliderVal,
        'max': calculatedVal,
      },
    });

    // console.log(calcSlider.noUiSlider.get());

    if ( calcSlider.noUiSlider.get() == 1 ) {
      calcSlider.noUiSlider.updateOptions({
        start: calculatedVal,
      });
    }

    return calculatedVal;
  };

  $(document).on('change input', '#ref_cases, #lab_expenses_month', function() {
    const labExpensesYear = convertCurrencyToNumbers($('#lab_expenses_month').val()) * 12;
    const refCases = $('#ref_cases').val() * 1;
    const calculatedVal = Math.round( labExpensesYear * refCases / 5 / 1000 ) * 1000;
    const minSliderVal = calculatedVal / 2;

    const startVal = $('#calc-slider').attr('data-current-val') ? convertCurrencyToNumbers($('#calc-slider').attr('data-current-val')) : calculatedVal;
    // const step = (calculatedVal - minSliderVal) / 9;
    $('.js-investement-alowed').text(`${new Intl.NumberFormat('en-US').format(calculatedVal)}`);
    $('.calc-slider .js-min-val').text(`$ ${new Intl.NumberFormat('en-US').format(minSliderVal)}`);
    $('.calc-slider .js-max-val').text(`$ ${new Intl.NumberFormat('en-US').format(calculatedVal)}`);

    calcSlider.noUiSlider.updateOptions({
      start: Math.round(startVal / 1000 ) * 1000,
      range: {
        'min': minSliderVal,
        'max': calculatedVal,
      },
    });
  });


  const calcSharesPurchased2 = () => {
    const investmentAllowed = calcInvestmentAllowed();
    const sharePrice = calcSharePrice();

    const calculatedVal = investmentAllowed / sharePrice;

    return calculatedVal;
  };

  const calcExitValue2 = () => {
    const sharePrice = 1.5;
    const sharesPurchased = calcSlider.noUiSlider.get() * 1 / sharePrice;

    const exitSharePrice = calcExitSharePrice();

    const calculatedVal = sharesPurchased * exitSharePrice;

    // console.log(calculatedVal);
    return calculatedVal;
  };

  const calcSeriesC2 = () => {
    // const investmentAllowed = $('#lab_expenses_month').val() * 12 * 2;
    const sharePrice = 12;
    const sharesPurchased = calcSlider.noUiSlider.get() * 1 / sharePrice;

    const calculatedVal = sharesPurchased * 14.793792925;

    return calculatedVal;
  };

  const calcSeriesB2 = () => {
    const exitValue = calcExitValue2();

    const calculatedVal = Math.round(exitValue);

    // console.log(calculatedVal);
    return calculatedVal;
  };

  const setCalcResults2 = () => {
    const seriesB = calcSeriesB2();
    const seriesC = calcSeriesC2();
    const investmentAllowed = calcInvestmentAllowed();
    $('.js-investement-alowed').text(new Intl.NumberFormat('en-US').format(investmentAllowed));
    $('.js-shares-purchased2').text(Math.round(calcSharesPurchased2()));
    $('.js-share-price').text(calcSharePrice().toFixed(2));
    $('.js-exit-share-price').text(calcExitSharePrice());
    $('.js-exit-value2').text(new Intl.NumberFormat('en-US').format(Math.round(calcExitValue2() / 1000 ) * 1000));
    $('.js-exit-value2-2').text(new Intl.NumberFormat('en-US').format(Math.round(seriesC / 1000 ) * 1000));

    if ( investmentAllowed < 20000 ) {
      $('.c-input-wrap__error').addClass('is-active');
      $('.c-input-wrap__slider').addClass('is-disabled');
      $('.results-row').addClass('is-disabled');
    } else {
      $('.c-input-wrap__error').removeClass('is-active');
      $('.c-input-wrap__slider').removeClass('is-disabled');
      $('.results-row').removeClass('is-disabled');
    }
  }

  $('.js-calc-form2').on('submit', () => {
    const formValidation = $('.js-calc-form2').valid();
    const labExpensesMonth = convertCurrencyToNumbers($('#lab_expenses_month').val());
    const refCases = $('#ref_cases').val();
    const userID = $('.js-user-id').val();
    const userRole = $('.js-user-role1').val();
    const hsListId = $('.js-hs-id').val();
    const email = $('.js-email').val();
    // const hutk = getCookie('hubspotutk');
    const security = $('#calcsecurity').val();

    if ( formValidation != true ) {
      return;
    }

    window.dataLayer.push({
      'event': 'calculate',
      'role': userRole,
    });

    setCalcResults2();

    $.when( $.ajax({
      url: '/wp-admin/admin-ajax.php',
      method: 'post',
      dataType: 'JSON',
      data: {
        'action': 'calc_users_meta',
        'user_id': userID,
        'lab_expenses_month': labExpensesMonth,
        'ref_cases': refCases,
        'investment_val': calcSlider.noUiSlider.get(),
        'security': security,
      },
    }))
    .then(
      res => {
        if ( hsListId ) {
          $.when( $.ajax({
            url: `https://api.hsforms.com/submissions/v3/integration/submit/20247445/${hsListId}`,
            method: 'post',
            contentType: 'application/json',
            data: JSON.stringify({
              "submittedAt": Date.now(), // This millisecond timestamp is optional. Update the value from "1517927174000" to avoid an INVALID_TIMESTAMP error.
              "fields": [
                {
                  "name": "email",
                  "value": email,
                },
                {
                  "name": "lab_expences_per_month",
                  "value": labExpensesMonth,
                },
                {
                  "name": "number_of_referral_cases_per_month",
                  "value": refCases,
                },
              ],
              "context": {
                // "hutk": hutk,
                "pageUri": window.location.href,
                "pageName": $('head title').text(),
                "ipAddress": window.userIP,
              },
            }),
          }))
          .then( res => {
            console.log(res);
          })
          .catch( e => {
            const errorMsg = `time: ${new Date().toUTCString()}, email: ${email}, ${JSON.stringify(e)}`;
            ajaxDebug(errorMsg);
            console.log(errorMsg);
          });
        }
      },
      e => {
        const errorMsg = `time: ${new Date().toUTCString()}, email: ${email}, ${JSON.stringify(e)}`;
        ajaxDebug(errorMsg);
        console.log(errorMsg);
      }
    );

    return false;
  });

  if ( $('.js-calc-form2').length ) {
    setCalcResults2();
  }

  //questionaire popup
  $(document).on('change click', '.questionaire-group input[type="radio"]', (e) => {
    const that = $(e.currentTarget);

    if ( that.val().toLowerCase() == 'no' ) {
      $('.js-questionnaire-error').trigger('click');
    }
  });

  $(document).on('change', '.btn-group[data-condition="true"] input[type="radio"], .btn-group[data-condition="true"] input[type="checkbox"], .select-group[data-condition="true"] select', e => {
    const that = $(e.currentTarget);
    const thatVal = that.val();
    const thatParent = that.closest('.btn-group, .select-group');
    const thatIf = thatParent.attr('data-if');
    const thatThenHide = thatParent.attr('data-then-hide');
    const thatThenHideArr = thatThenHide.split(',');

    thatThenHideArr.forEach(e => {
      const el = $(`input[name="${ e }"], select[name="${ e }"]`);

      if ( thatIf == thatVal ) {
        el.closest('.questionaire-group').addClass('hidden');
      } else {
        el.closest('.questionaire-group').removeClass('hidden');
      }
    });
  });

  $(document).on('click', '.fancybox-close', () => {
    $.fancybox.close();
  });

  const toggleQuestionaireGroup = () => {
    $('.form-group-box').each((i, e) => {
      const that = $(e);
      const childrenCount = that.find('.questionaire-group').length;
      const hiddenChildrenCount = that.find('.questionaire-group.hidden').length;

      if ( childrenCount == hiddenChildrenCount ) {
        // console.log(that);
        that.addClass('hidden');
      } else {
        that.removeClass('hidden');
        // console.log(':(', that);
      }
    });
  }

  if ( $('.js-questionnaire').length ) {
    toggleQuestionaireGroup();
  }


  //questionnaire conditions
  $(document).on('change', '.js-e-role', e => {
    const that = $(e.currentTarget);
    const thatVal = that.val();
    console.log(thatVal);
    $('.js-diff-role').addClass('hidden');
    $(`.js-diff-role[data-role*="${ thatVal }"]`).removeClass('hidden');

    toggleQuestionaireGroup();
  });

  //questionnaire form
  $('.js-questionnaire').on('submit', () => {
    const hsListId = $('.js-hs-id2').val();
    const email = $('.js-email').val();
    const userID = $('.js-user-id').val();
    const userRole = $('.js-user-role').val();
    // const hutk = getCookie('hubspotutk');
    const security = $('#calcsecurity').val();
    let noVals = 0;
    let i = 0;
    const stepsCount = $('.js-questionnaire .questionaire-group:not(.hidden) .btn-group[data-required="true"]').length + $('.js-questionnaire .questionaire-group:not(.hidden) .input-group[data-required="true"]').length + $('.js-questionnaire .questionaire-group:not(.hidden) .textarea-group[data-required="true"]').length + $('.js-questionnaire .questionaire-group:not(.hidden) .select-group[data-required="true"]').length;

    $('.js-questionnaire button[type="submit"]').addClass('is-loading');

    let fields = [
      {
        "name": "email",
        "value": email,
      },
    ];

    $('.js-questionnaire .btn-group').each(function() {
      const that = $(this);

      if ( $(this).find('input[type="radio"]:checked').val() ) {
        if ( $(this).find('input[type="radio"]:checked').val().toLowerCase() == 'no') {
          noVals++ ;
        }

        fields.push({
          "name": $(this).find('input[type="radio"]:checked').attr('name'),
          "value": $(this).find('input[type="radio"]:checked').val(),
        });

        // console.log(fields);

        if ( that.attr('data-required') == 'true' ) {
          i++ ;
        }
      }

      if ( $(this).find('input[type="checkbox"]:checked').val() ) {
        let checkAttrName = $(this).find('input[type="checkbox"]:checked').attr('name');
        fields.push({
          "name": checkAttrName,
          "value": $(this).find(`input[name="${ checkAttrName }"]:checked`).map(function() {return this.value;}).get().join(', '),
        });

        if ( that.attr('data-required') == 'true' ) {
          i++ ;
        }
      }
    });

    $('.js-questionnaire .input-group').each(function() {
      const that = $(this);
      if ( $(this).find('input[type="text"]').val() ) {
        fields.push({
          "name": $(this).find('input[type="text"]').attr('name'),
          "value": $(this).find('input[type="text"]').val(),
        });

        if ( that.attr('data-required') == 'true' ) {
          i++ ;
        }
      }
    });

    $('.js-questionnaire .textarea-group').each(function() {
      const that = $(this);
      if ( $(this).find('textarea').val() ) {
        fields.push({
          "name": $(this).find('textarea').attr('name'),
          "value": $(this).find('textarea').val(),
        });

        if ( that.attr('data-required') == 'true' ) {
          i++ ;
        }
      }
    });

    $('.js-questionnaire .select-group').each(function() {
      const that = $(this);
      if ( $(this).find('select').val() ) {
        fields.push({
          "name": $(this).find('select').attr('name'),
          "value": $(this).find('select').val(),
        });

        if ( that.attr('data-required') == 'true' ) {
          i++ ;
        }
      }
    });

    if ( noVals != 0 || i != stepsCount ) {
      console.log('i', i);
      console.log('stepsCount', stepsCount);
      $('.js-questionnaire-error').trigger('click');
      // console.log('error');
      $('.js-questionnaire button[type="submit"]').removeClass('is-loading');
      return false;
    } else {
      window.dataLayer.push({
        'event': 'qst',
        'role': userRole,
      });

      let hsForm = true;

      $.when( $.ajax({
        url: '/wp-admin/admin-ajax.php',
        type: 'post',
        dataType: 'JSON',
        data: {
          'action': 'questionaire',
          'user_id': userID,
          'security': security,
          'e_role': $('.js-e-role').length ? $('.js-e-role').val() : '',
        },
      }))
      .then(
        res => {
          // console.log(res);
          if ( !$('body').hasClass('page-template-page-dashboard-steps') || $('.dashboard-wrap').attr('data-role') == 'employee' ) {
            $('.questionaire-wrap').addClass('hidden');
            $('.ty-box').removeClass('hidden');
            $('.questionaire-step-li').addClass('is-active').removeClass('is-waiting');
          }

          $.ajax({
            url: `https://api.hsforms.com/submissions/v3/integration/submit/20247445/${hsListId}`,
            method: 'post',
            contentType: 'application/json',
            // dataType: 'JSON',
            data: JSON.stringify({
              "submittedAt": Date.now(), // This millisecond timestamp is optional. Update the value from "1517927174000" to avoid an INVALID_TIMESTAMP error.
              "fields": fields,
              "context": {
                // "hutk": hutk,
                "pageUri": window.location.href,
                "pageName": $('head title').text(),
                "ipAddress": window.userIP,
              },
            }),
          })
          .catch(
            e => {
              $('.js-questionnaire button[type="submit"]').removeClass('is-loading');
              const errorMsg = `time: ${new Date().toUTCString()}, email: ${email}, ${JSON.stringify(e)}`;
              ajaxDebug(errorMsg);
              console.log(errorMsg);

              if ( e.status == 400 ) {
                hsForm = false;
              }
            }
          )

          .then(
            () => {
              if ( hsForm == true ) {
                if ( $('body').hasClass('page-template-page-dashboard-steps') && $('.dashboard-wrap').attr('data-role') != 'employee' || $('body').hasClass('page-template-page-dashboard2') ) {
                  window.location = `${window.location.origin}/${window.location.pathname}?step=3`;
                }
                console.log(res);
                $('.js-questionnaire button[type="submit"]').removeClass('is-loading');
              } else {
                $.ajax({
                  url: `https://api.hsforms.com/submissions/v3/integration/submit/20247445/${hsListId}`,
                  method: 'post',
                  contentType: 'application/json',
                  // dataType: 'JSON',
                  data: JSON.stringify({
                    "submittedAt": Date.now(), // This millisecond timestamp is optional. Update the value from "1517927174000" to avoid an INVALID_TIMESTAMP error.
                    "fields": fields,
                    "context": {
                      // "hutk": hutk,
                      "pageUri": window.location.href,
                      "pageName": $('head title').text(),
                      "ipAddress": window.userIP,
                    },
                  }),
                })
                .then(res => {
                  if ( $('body').hasClass('page-template-page-dashboard-steps') && $('.dashboard-wrap').attr('data-role') != 'employee' || $('body').hasClass('page-template-page-dashboard2') ) {
                    window.location = `${window.location.origin}/${window.location.pathname}?step=3`;
                  }
                  console.log(res);
                  $('.js-questionnaire button[type="submit"]').removeClass('is-loading');
                });
              }
            }
          );
        },
        e => {
          alert('Something went wrong, please conctact with website administrator');
          $('.js-questionnaire button[type="submit"]').removeClass('is-loading');
          const errorMsg = `time: ${new Date().toUTCString()}, email: ${email}, ${JSON.stringify(e)}`;
          ajaxDebug(errorMsg);
          console.log(errorMsg);
        }
      );
    }

    return false;
  });

  //employee form

  $('.js-meeting-form').on('submit', () => {
    const form = $('.js-meeting-form');
    const hsListId = $('.js-hs-id3').val();
    const userID = $('.js-user-id').val();
    const eDate = $('.js-e-date').val();
    const security = $('#calcsecurity').val();
    const email = form.find('input[name="email"]').val();

    let fields = [
      {
        "name": "email",
        "value": email,
      },
      {
        "name": "firstname",
        "value": form.find('input[name="name"]').val(),
      },
      {
        "name": "lastname",
        "value": form.find('input[name="surname"]').val(),
      },
      {
        "name": "webinar_date",
        "value": eDate,
      },
    ];

    $.when( $.ajax({
      url: '/wp-admin/admin-ajax.php',
      method: 'post',
      dataType: 'JSON',
      data: {
        'action': 'employee_meeting',
        'user_id': userID,
        'security': security,
      },
    }))
    .then(
      res => {
        if ( hsListId ) {
          $.when( $.ajax({
            url: `https://api.hsforms.com/submissions/v3/integration/submit/20247445/${hsListId}`,
            method: 'post',
            contentType: 'application/json',
            data: JSON.stringify({
              "submittedAt": Date.now(), // This millisecond timestamp is optional. Update the value from "1517927174000" to avoid an INVALID_TIMESTAMP error.
              "fields": fields,
              "context": {
                // "hutk": hutk,
                "pageUri": window.location.href,
                "pageName": $('head title').text(),
                "ipAddress": window.userIP,
              },
            }),
          }))
          .then( res => {
            $('.js-meeting-form').addClass('hidden');
            $('.js-ty-box2').removeClass('hidden');
          })
          .catch( e => {
            alert('Something went wrong, please conctact with website administrator');
            const errorMsg = `time: ${new Date().toUTCString()}, email: ${email}, ${JSON.stringify(e)}`;
            ajaxDebug(errorMsg);
            console.log(errorMsg);
          });
        }
      },
      e => {
        alert('Something went wrong, please conctact with website administrator');
        const errorMsg = `time: ${new Date().toUTCString()}, email: ${email}, ${JSON.stringify(e)}`;
        ajaxDebug(errorMsg);
        console.log(errorMsg);
      }
    );

    return false;
  });


  //change user's role
  $(document).on('click', '.js-change-role', e => {
    const that = $(e.currentTarget);
    const role = that.attr('data-role');
    const userID = $('.js-user-id').val();
    const security = $('#calcsecurity').val();

    $.ajax({
      url: '/wp-admin/admin-ajax.php',
      method: 'post',
      dataType: 'JSON',
      data: {
        'action': 'change_role',
        'role': role,
        'user_id': userID,
        'security': security,
      },
    })
    .done(function() {
      window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      }
      location.reload();
    })
    .fail(function(e) {
      alert('Something went wrong, please conctact with website administrator');
      const errorMsg = `time: ${new Date().toUTCString()}, ${JSON.stringify(e)}`;
      ajaxDebug(errorMsg);
      console.log(errorMsg);
    });

    return false;
  });

  if ( calcSlider2 ) {
    calcSlider2.noUiSlider.on('update', function (values, handle) {
      $('.js-calc-slider2-val').text(`$ ${new Intl.NumberFormat('en-US').format(values[handle])}`);

      if ( $('.calc2--rev2').length ) {
        if ( values[handle] == calcSlider2.noUiSlider.options.range.max ) {
          $('.js-founding-col').addClass('is-active');
        } else {
          $('.js-founding-col').removeClass('is-active');
        }

        setCalcResults();
      }

      if ( $('.calc-section2').length ) {
        setCookie('currentSliderVal',values[handle],1);
      }
    });
  }

  $(document).on('change', '.calc-section2 #revenue', e => {
    const revenue = $(e.currentTarget).val();
    setCookie('revenue',revenue,1);
  });

  $(document).on('change', '.calc-section2 #ebitda', e => {
    const ebitda = $(e.currentTarget).val();
    setCookie('ebitda',ebitda,1);
  });

  $(document).on('change', '.calc-section2 #f-time-doctors', e => {
    const doctors = $(e.currentTarget).val();
    setCookie('doctors',doctors,1);
  });

  $(document).on('change', '.calc-section2 #exam-rooms', e => {
    const rooms = $(e.currentTarget).val();
    setCookie('rooms',rooms,1);
  });

  $(document).on('change', '.calc-section2 #multiple-today', e => {
    const multiple = $(e.currentTarget).val();
    setCookie('multiple',multiple,1);
  });

  $(document).on('change input', '.calc-section .calc2--rev2 #multiple-today', e => {
    console.log('test');
    setCalcResults();
  });



  $(document).on('change input', '#multiple-today', e => {
    const multiple = $(e.currentTarget).val();
    $('.js-multiple-other').text(multiple);
  });

  $(document).on('click', '.js-toggle-signup-form', () => {
    $('.calc-signup-form').show();
    $('.calc-section2 .md-col2').addClass('is-active-calc');

    return false;
  });
});
